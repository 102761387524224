var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Subnav'),_c('div',{staticClass:"banner",style:(`background: url(${require('@/images/intor-images/intor-banner.png')})center no-repeat;`)},[_vm._m(0)]),_c('div',{staticClass:"process-warp warp"},[_c('h3',{staticClass:"process-tit"},[_vm._v("资金商户与微信支付直接结算")]),_c('p',{staticClass:"process-text"},[_vm._v("E0123纯信息服务，更安全，更快捷")]),_c('div',{staticClass:"process-img",style:(`background: url(${require('@/images/intor-images/process-img.png')})center no-repeat;`)})]),_vm._m(1),_c('div',{staticClass:"container-warp warp"},[_c('div',{staticClass:"intro-item"},[_vm._m(2),_c('h4',{staticClass:"part-tit"},[_vm._v("案列介绍")]),_c('div',{staticClass:"icon-warp"},[_c('div',[_c('i',{staticClass:"supermarket-icon",style:(`background: url(${require('@/images/intor-images/supermarket.png')})center no-repeat;`)}),_vm._v("电脑扫码")])]),_vm._m(3)]),_c('div',{staticClass:"intro-item"},[_vm._m(4),_c('h4',{staticClass:"part-tit"},[_vm._v("案列介绍")]),_c('div',{staticClass:"icon-warp PayCard"},[_c('div',{staticClass:"active"},[_c('i',{staticClass:"supermarket-icon",style:(`background: url(${require('@/images/intor-images/supermarket.png')})center no-repeat;`)}),_vm._v("电脑扫码")]),_c('div',[_c('i',{staticClass:"store-icon",style:(`background: url(${require('@/images/intor-images/store.png')})center no-repeat;`)}),_vm._v("手机扫码")])]),_vm._m(5)]),_c('div',{staticClass:"intro-item"},[_vm._m(6),_c('h4',{staticClass:"part-tit"},[_vm._v("案列介绍")]),_c('div',{staticClass:"icon-warp"},[_c('div',[_c('i',{staticClass:"commerce",style:(`background: url(${require('@/images/intor-images/commerce.png')})center no-repeat;`)}),_vm._v("微商城")])]),_vm._m(7)]),_vm._m(8)]),_vm._m(9)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intor-banner"},[_c('h2',{staticClass:"tit"},[_vm._v("帮你快速接入"),_c('span',{staticClass:"green"},[_vm._v("微信支付")])]),_c('p',{staticClass:"des"},[_vm._v("E0123整合"),_c('span',{staticClass:"vs"},[_vm._v("|")]),_vm._v("免费"),_c('span',{staticClass:"vs"},[_vm._v("|")]),_vm._v("扫码收款"),_c('br'),_vm._v("轻松实现收款、查账")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"intor-nav warp"},[_c('li',{staticClass:"active"},[_c('span',[_vm._v("扫码支付")])]),_c('li',[_c('span',[_vm._v("刷卡支付")])]),_c('li',[_c('span',[_vm._v("微商城支付")])]),_c('li',[_c('span',[_vm._v("消息支付")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intro-con"},[_c('div',{staticClass:"depict"},[_c('h3',{staticClass:"title"},[_vm._v("扫码支付介绍")]),_c('p',{staticClass:"state"},[_vm._v("扫码支付是指商户系统按微信支付协议生成支付二维码，用户再用微信\"扫一扫\"来完成支付。")]),_c('span',{staticClass:"suits"},[_vm._v("适用于PC网站支付、实体店单品等场景。")])]),_c('div',{staticClass:"photo"},[_c('img',{attrs:{"src":require("../../images/intor-images/item-img1.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"case-body"},[_c('div',{staticClass:"img-chart warp active"},[_c('span',{staticClass:"arrow"}),_c('div',[_c('img',{attrs:{"src":require("../../images/intor-images/wechatpay-icon.png")}}),_c('div',[_vm._v("微信扫码")])]),_c('div',[_c('img',{attrs:{"src":require("../../images/intor-images/wechatpay-icon2.png")}}),_c('div',[_vm._v("储值卡扫码")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intro-con"},[_c('div',{staticClass:"depict"},[_c('h3',{staticClass:"title"},[_vm._v("刷卡支付介绍")]),_c('p',{staticClass:"state"},[_vm._v("刷卡支付是指用户展示微信钱包内的“刷卡条码/二维码”给商户系统扫描后直接完成支付。")]),_c('span',{staticClass:"suits"},[_vm._v("适用于线下面对面收银的场景，如超市、便利店等。")])]),_c('div',{staticClass:"photo"},[_c('img',{attrs:{"src":require("../../images/intor-images/item-img2.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"case-body"},[_c('div',{staticClass:"img-chart warp active"},[_c('span',{staticClass:"arrow",staticStyle:{"margin-left":"-70px"}}),_c('div',[_c('img',{attrs:{"src":require("../../images/intor-images/paybycard1.png")}}),_c('div',[_vm._v("微信刷卡")])]),_c('div',[_c('img',{attrs:{"src":require("../../images/intor-images/paybycard2.png")}}),_c('div',[_vm._v("储值卡刷卡")])])]),_c('div',{staticClass:"img-chart warp"},[_c('span',{staticClass:"arrow",staticStyle:{"margin-left":"45px"}}),_c('div',[_c('img',{attrs:{"src":require("../../images/intor-images/paybycard3.png")}}),_c('div',[_vm._v("选择支付方式")])]),_c('div',[_c('img',{attrs:{"src":require("../../images/intor-images/paybycard4.png")}}),_c('div',[_vm._v("微信刷卡")])]),_c('div',[_c('img',{attrs:{"src":require("../../images/intor-images/paybycard5.png")}}),_c('div',[_vm._v("储值卡刷卡")])]),_c('div',[_c('img',{attrs:{"src":require("../../images/intor-images/paybycard6.png")}}),_c('div',[_vm._v("扫码")])]),_c('div',[_c('img',{attrs:{"src":require("../../images/intor-images/paybycard7.png")}}),_c('div',[_vm._v("微信支付")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intro-con"},[_c('div',{staticClass:"depict"},[_c('h3',{staticClass:"title"},[_vm._v("微商城支付介绍")]),_c('p',{staticClass:"state"},[_vm._v("微商城支付是指用户在微商城购买商品时使用的支付方式。")]),_c('span',{staticClass:"suits"},[_vm._v("适用于线上购买商品的时候选择支付方式的场景。")])]),_c('div',{staticClass:"photo"},[_c('img',{attrs:{"src":require("../../images/intor-images/item-img3.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"case-body"},[_c('div',{staticClass:"img-chart warp active"},[_c('span',{staticClass:"arrow"}),_c('div',[_c('img',{attrs:{"src":require("../../images/intor-images/wechatshop1.png")}}),_c('div',[_vm._v("微信扫码")])]),_c('div',[_c('img',{attrs:{"src":require("../../images/intor-images/wechatshop2.png")}}),_c('div',[_vm._v("储值卡扫码")])]),_c('div',[_c('img',{attrs:{"src":require("../../images/intor-images/wechatshop3.png")}}),_c('div',[_vm._v("支付界面")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intro-item"},[_c('div',{staticClass:"intro-con"},[_c('div',{staticClass:"depict"},[_c('h3',{staticClass:"title"},[_vm._v("消息支付介绍")]),_c('p',{staticClass:"state"},[_vm._v("微商城支付是指商家发送一条支付消息到客户手机微信上，客户点击链接进行支付即完成。")]),_c('span',{staticClass:"suits"},[_vm._v("适用于线下实体店开单收款操作。")])]),_c('div',{staticClass:"photo"},[_c('img',{attrs:{"src":require("../../images/intor-images/item-img4.png")}})])]),_c('h4',{staticClass:"part-tit"},[_vm._v("案列介绍")]),_c('div',{staticClass:"icon-warp"},[_c('div',[_c('i',{staticClass:"commerce"}),_vm._v("消息支付")])]),_c('div',{staticClass:"case-body"},[_c('div',{staticClass:"img-chart warp active"},[_c('span',{staticClass:"arrow"}),_c('div',[_c('img',{attrs:{"src":require("../../images/intor-images/pay-icon1.png")}}),_c('div',[_vm._v("消息支付")])]),_c('div',[_c('img',{attrs:{"src":require("../../images/intor-images/pay-icon2.png")}}),_c('div',[_vm._v("接受消息")])]),_c('div',[_c('img',{attrs:{"src":require("../../images/intor-images/pay-icon3.png")}}),_c('div',[_vm._v("确认支付")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"apply warp"},[_c('div',{staticStyle:{"overflow":"hidden","margin-bottom":"35px"}},[_c('h4',{staticStyle:{"float":"left"}},[_vm._v("申请流程")]),_c('a',{staticStyle:{"float":"right"},attrs:{"href":"#"}},[_vm._v(" 了解详细流程")])]),_c('ul',{staticClass:"apply-process"},[_c('li',[_c('i',{},[_c('span',[_vm._v("1")])]),_c('div',[_c('h4',[_vm._v("申请E0123商户")]),_c('p',[_vm._v("关注公众号,点击CRM注册商户并通过商户和门店认证。")])])]),_c('li',[_c('i',[_c('span',[_vm._v("2")])]),_c('div',[_c('h4',[_vm._v("申请微信支付")]),_c('p',[_vm._v("提交资料，E0123带代申请，待资料审核通过后，完成账户认证。")]),_c('a',{attrs:{"href":"#"}},[_vm._v(">教材")])])]),_c('li',[_c('i',[_c('span',[_vm._v("3")])]),_c('i',{staticStyle:{"right":"0px","font-size":"14px"}},[_c('span',[_vm._v("ok")])]),_c('div',[_c('p',[_vm._v("OK")])])])])])
}]

export { render, staticRenderFns }