import { render, staticRenderFns } from "./PAD_assist.vue?vue&type=template&id=410db1c6&scoped=true"
import script from "./PAD_assist.vue?vue&type=script&lang=js"
export * from "./PAD_assist.vue?vue&type=script&lang=js"
import style0 from "./PAD_assist.vue?vue&type=style&index=0&id=410db1c6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "410db1c6",
  null
  
)

export default component.exports